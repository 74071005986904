import styles from "../../../_metronic/assets/sass/components/custom-map.module.scss";

export function LocationPin({
  text,
  key,
}: {
  lat: any;
  lng: any;
  text: string;
  key: any;
}): JSX.Element {
  return (
    <div key={key} className={styles.pin}>
      <img src={"/red-map-marker.png"}></img>
      <p className={styles.pinText}>{text}</p>
    </div>
  );
}
