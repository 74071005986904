import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import targetMerchantService from "../../services/merchantManagement/Target.service";


interface InitialStateInterface {
    targetMerchantListSlice: any,
    targetMerchantHistoryListSlice: any
}

const initialState = {
    targetMerchantListSlice: {},
    targetMerchantHistoryListSlice: {},
} as InitialStateInterface;

export const getTargetMerchant = createAsyncThunk(
    "getTargetMerchantList",
    async (req: any) => {
        try {
            return await targetMerchantService.getTargetMerchantService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportTargetMerchant = createAsyncThunk(
    "exportTargetMerchantList",
    async (req: any) => {
        try {
            return await targetMerchantService.exportTargetMerchantService(req).then(response => response);
        } catch (error) { }
    }
);

export const getTargetMerchantWithId = createAsyncThunk(
    "getTargetMerchantWithId",
    async (req: any) => {
        try {
            return await targetMerchantService.getTargetMerchantWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const postTargetMerchantHistory = createAsyncThunk(
    "postTargetMerchantHistory",
    async (req: any, thunkAPI) => {
        try {
            return await targetMerchantService.postTargetMerchantHistoryService(req).then(response => response);
        } catch (error) { }
    }
);

export const getTargetMerchantHistoryWithId = createAsyncThunk(
    "getTargetMerchantHistoryWithId",
    async (req: any) => {
        try {
            return await targetMerchantService.getTargetMerchantHistoryWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const putTargetMerchant= createAsyncThunk(
    "putTargetMerchant",
    async (req: any, thunkAPI) => {
        try {
            return await targetMerchantService.putTargetMerchantService(req).then(response => response);
        } catch (error) { }
    }
);

export const getRequestLinkWithId = createAsyncThunk(
    "getRequestLinkWithId",
    async (req: any) => {
        try {
            return await targetMerchantService.getRequestLinkWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const targetMerchantSlice = createSlice({
    name: "targetMerchantSlice",
    initialState,
    reducers: {
        clearItems: (state, action) => {
          state.targetMerchantListSlice = null
          state.targetMerchantHistoryListSlice = null
        },
      },
    extraReducers: (builder) => {
        builder.addCase(getTargetMerchant.fulfilled, (state, action) => {
            state.targetMerchantListSlice = action.payload?.result ? action.payload.result?.target_merchant_list : {};
        });
        builder.addCase(getTargetMerchantHistoryWithId.fulfilled, (state, action) => {
            state.targetMerchantHistoryListSlice = action.payload?.result ? action.payload.result?.target_merchant_communication_history_list : {};
        });
    },
});

export default targetMerchantSlice;
