/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard, KTSVG } from "../../../_metronic/helpers"
import { ListViewProvider } from "../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../slices/components/componentsSlice"
import clsx from 'clsx'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import fontAwesomeHelper from "../../utils/font-awesome-helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/free-brands-svg-icons"
import { faX } from "@fortawesome/pro-light-svg-icons"
import { right } from "@popperjs/core"

interface Props {
    memberManagementList: any[]
}
const MemberManagementList: FC<Props> = ({ memberManagementList }) => {
    const [totalRowCountVal, settotalRowCountVal] = useState(0)
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const [resetDataTable, setresetDataTable] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();

    const [selectedRowId, setSelectedRowId] = useState(undefined)

    const [selectedRow, setSelectedRow] = useState<any>()


    const formik = useFormik({
        initialValues: {
            memberManagementId: '',
            name: '',
            iconString: '',
            shortDescription: '',
            type: [],
            queryId: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Name is required'),
            iconString: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Icon is required'),
            shortDescription: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Short Description is required'),
            type: Yup.array()
                .min(1, "Shoutback Criterias is required")
                .required('Shoutback Criterias is required')
        }),
        onSubmit: (values, actions) => {
            
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
        settotalRowCountVal(memberManagementList.length)
        setresetDataTable(!resetDataTable)
    }, [memberManagementList])


    const columns = [
        {
            name: 'Name',
            selector: (row: any) => <div>{row?.name}</div>,
            width: "auto",
        },
        {
            name: 'Partner Name',
            selector: (row: any) => <div>{row?.partner_name}</div>,
            width: "auto",
        },
        {
            name: 'Registration Date',
            selector: (row: any) => <div>{row?.date}</div>,
            width: "150px",
        },
        {
            name: 'Country',
            selector: (row: any) => <div>{row?.country}</div>,
            width: "100px",
        },
        {
            name: 'Status',
            selector: (row: any) => row?.status ? <span className='badge badge-light-success fs-5 fw-semibold'>{"Active"}</span> : <span className='badge badge-light-danger fs-5 fw-semibold   '>{"Disabled"}</span>,
            width: "150px",
        },
        {
            name: 'Direct Message',
            selector: (row: any) =>
                <>
                    <div className='btn btn-icon btn-bg-light btn-pending-color-primary btn-sm me-1'

                    >
                        <KTSVG path='/media/icons/duotune/abstract/abs007.svg' className='svg-icon-3' />
                    </div>
                </>
            ,
            width: "100px",
        },
        {
            name: 'Detail',
            selector: (row: any) =>
                <>
                    <div className='btn btn-icon btn-bg-light btn-pending-color-primary btn-sm me-1'
                        onClick={() => {
                            setSelectedRowId(row.id)
                            setSelectedRow(row)
                            dispatch(componentsSlice.actions.openModal({}))
                        }}
                    >
                        <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
                    </div>
                </>
            ,
            width: "100px",
        },
        // {
        //     name: 'Action',
        //     selector: (row: any) =>
        //         <>
        //             <div className='d-flex justify-content-end flex-shrink-0'>
        //                 <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'
        //                     onClick={() => {
        //                         setprocessName("Message")
        //                         setSelectedRowId(row.id)
        //                         dispatch(componentsSlice.actions.openModal({}))
        //                     }}
        //                 >
        //                     <KTSVG path='/media/icons/duotune/communication/com007.svg' className='svg-icon-2x' />
        //                 </div>
        //                 <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'
        //                     onClick={() => {
        //                         setprocessName("Edit")
        //                         setSelectedRowId(row.id)
        //                         dispatch(componentsSlice.actions.openModal({}))
        //                     }}
        //                 >
        //                     <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2x' />
        //                 </div>
        //             </div>
        //         </>
        //     ,
        //     width: "150px",
        // },
    ];

    useEffect(() => {
        //setdataTableisBusy(true) Temp closed until api ready
        //dispatch(getStorage());  Temp closed until api ready
    }, [activePage, pageSize])


    useEffect(() => {
        if (modalIsOpen === undefined) {
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])



    const onChangePage = (page: any) => {
        setActivePage(page);
    };
    const onChangeRowsPerPage = (size: any) => {
        setPageSize(size);
    };
    // For DataTable Event End


    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Shoutback" : "Create Shoutback"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Name</label>
                                        <input
                                            placeholder='Name'
                                            type='text'
                                            {...formik.getFieldProps('name')}
                                            name='name'
                                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                            autoComplete='off'
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Shoutback Request</label>
                                        <div className="row">
                                            <div className="col-11"><input
                                                placeholder='Icon'
                                                type='text'
                                                {...formik.getFieldProps('iconString')}
                                                name='iconString'
                                                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                                autoComplete='off'
                                            />
                                                {formik.touched.iconString && formik.errors.iconString && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.iconString}</span>
                                                        </div>
                                                    </div>
                                                )}</div>
                                            <div className="col-1">
                                                <FontAwesomeIcon
                                                    icon={formik.values.iconString !== undefined ? fontAwesomeHelper(formik.values.iconString) as IconDefinition : faX} size={"2x"} style={{ marginTop: "0.5rem" }}
                                                ></FontAwesomeIcon>
                                            </div>
                                        </div>

                                        <div className=" mt-4">
                                            <input
                                                placeholder='Short Description'
                                                type='text'
                                                {...formik.getFieldProps('shortDescription')}
                                                name='shortDescription'
                                                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                                autoComplete='off'
                                            />
                                            {formik.touched.shortDescription && formik.errors.shortDescription && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.shortDescription}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Update":
                return <>

                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={memberManagementList}
                    title={"Member Management"}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowCountVal}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    isBusy={dataTableisBusy}
                    
                    showTitle
isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                // findProcessScreen(processName)
                <CustomModal title={`Detail`}>
                    {
                        selectedRow?.partner_name ? <>  <div className='fv-row mb-7'>
                            <label className='fw-bold fs-6 mb-2'>Partner Affiliation link</label>
                            <br />
                            <a href='#'>{'https://visobl.com/' + selectedRow.partner_name + '/' + selectedRow.name}</a>
                        </div></> : ""
                    }

                    <label className='fw-bold fs-6 mb-2'>Status</label>

                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            value=""
                            name='notifications'
                            checked={selectedRow?.status === 'Active' ? true : false}
                        />
                    </div>
                    <br />

                    {selectedRow?.partner_name ? <><label className='fw-bold fs-6 mb-2'>Referral URL Performance </label>
                        <ul className="list-group">
                            
                        </ul></> : ""}
                    <br />
                    <div className="col">
                        <button type='button' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' style={{ float: right }}>
                            Restore
                        </button>
                    </div>

                </CustomModal>
                : null
            }
        </>
    )
}

const MemberManagementListWrapper: FC<Props> = ({ memberManagementList }) => <QueryRequestProvider>
    <ListViewProvider>
        <MemberManagementList memberManagementList={memberManagementList} />
    </ListViewProvider>
</QueryRequestProvider>

export { MemberManagementListWrapper }
