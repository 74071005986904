/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import { CustomModal } from '../../../components/customModal/CustomModal'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { useDispatch } from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import { showToast } from '../../../components/custom-toasts/custom-toasts'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from '../../../../_metronic/helpers'
import { QueryRequestProvider } from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import { ListViewProvider } from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import moment from 'moment'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import CustomSelect from '../../../components/customModal/CustomSelect'
import { Tab, Tabs } from 'react-bootstrap'
import { CustomMap } from '../../../components/custom-map/custom-map'
import CustomRichTextbox from '../../../components/customTextbox/CustomRichTextbox'
import targetMerchantSlice, { exportTargetMerchant, getRequestLinkWithId, getTargetMerchant, getTargetMerchantHistoryWithId, getTargetMerchantWithId, postTargetMerchantHistory, putTargetMerchant } from '../../../slices/merchantManagement/targetRequestSlice'
import CustomDate from '../../../components/custom-date/custom-date'
import CustomPhoneTextbox from '../../../components/customTextbox/CustomPhoneTextbox'

interface Props {
  targetMerchantList: any
}

export const communicationChannelTypes = [
  {
    value: 0,
    label: "Email"
  },
  {
    value: 1,
    label: "Facebook"
  },
  {
    value: 2,
    label: "Instagram"
  },
  {
    value: 3,
    label: "LinkedIn"
  },
  {
    value: 4,
    label: "Phone"
  },
  {
    value: 5,
    label: "Twitter"
  },
  {
    value: 6,
    label: "Web"
  },
]

const TargetMerchantList: FC<Props> = ({ targetMerchantList }) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Detail')
  const { targetMerchantListSlice, targetMerchantHistoryListSlice } = useAppSelector((state) => state.targetMerchantSlice)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [merchant_id, setmerchant_id] = useState("")
  const [merchantLikeCount, setmerchantLikeCount] = useState("0")

  let customValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    website: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Website is required'),
    phone_number: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Phone Number is required'),
  })

  const formik = useFormik({
    initialValues: {
      id: '',
      merchant_name: '',
      email: '',
      website: '',
      phone_number: '',
      lat: 38.9095,
      lng: -77.0469,
      pvc: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      twitter: '',
    },
    validationSchema: customValidationSchema,
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  let customHistoryValidationSchema = Yup.object().shape({
    target_merchant_communication_channel_type: Yup.object().required('Type is required'),
    message_content: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Message Content is required'),
    communication_date: Yup.string()
      .required('Communication Date is required'),
  })

  const formikHistory = useFormik({
    initialValues: {
      id: '',
      target_merchant_communication_channel_type: undefined,
      message_content: '',
      communication_date: '',
    },
    validationSchema: customHistoryValidationSchema,
    onSubmit: (values, actions) => {
      try {
        createHistoryWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    dispatch(targetMerchantSlice.actions.clearItems({}))
  }, [])

  useEffect(() => {
    setdataTableisBusy(false)
  }, [targetMerchantList])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Detail')
      formik.resetForm()
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Merchant Name',
        selector: (row: any) => <div>{row?.merchant_name}</div>,
        width: 'auto',
      },
      {
        name: 'Like Count',
        selector: (row: any) => <div>{row?.like_count}</div>,
        width: 'auto',
      },
      {
        name: 'PVC',
        selector: (row: any) => <div>{Number(row?.predicted_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " xLP"}</div>,
        width: 'auto',
      },
      {
        name: 'Date Created',
        label: "CreateDateUtc",
        selector: (row: any) => <div>{moment(row.onboarding_date).format('MM/DD/YYYY')}</div>,
        width: '18%',
        sortable: true
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Detail')
                      dispatch(componentsSlice.actions.openModal({}))
                      TargetMerchantWithId(row.id)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const columnsHistory = useMemo(
    () => [
      {
        name: 'Channel Type',
        selector: (row: any) => <div>{communicationChannelTypes.find(x=>x.value === row.target_merchant_communication_channel_type)?.label}</div>,
        width: 'auto',
      },
      {
        name: 'Message Content',
        selector: (row: any) => <div>{row?.message_content}</div>,
        width: 'auto',
      },
      {
        name: 'Communication Date',
        selector: (row: any) => <div>{moment(row.communication_date).format("MM/DD/YYYY")}</div>,
        width: 'auto',
      },
    ],
    []
  )

  const customFilter = [
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Name</label>
          <CustomTextbox
            onChange={(e) =>
              isNotEmpty(e)
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'CompanyName'),
                  { name: 'CompanyName', comparator: 'Contains', value: e },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'CompanyName')])
            }
            placeHolder={'Type a Name'}
            name='name'
            value={
              filterQuery.find((x: any) => x.name === 'CompanyName') !== undefined
                ? filterQuery.find((x: any) => x.name === 'CompanyName')?.value
                : ''
            }
            customKeypressEvent={() => setfilterParams(filterQuery)}
          ></CustomTextbox>
        </>
      ),
    },

  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{ property: column?.label, direction: sortDirection === 'asc' ? 0 : 1 }])
  }
  useEffect(() => {
    dispatch(
      getTargetMerchant({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function TargetMerchantWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getTargetMerchantWithId(rowId)).then((s: any) => {
        formik.setValues({
          id: s?.payload?.result?.id,
          merchant_name: s?.payload?.result?.merchant_name,
          lat: s?.payload?.result?.latitude,
          lng: s?.payload?.result?.longitude,
          pvc: s?.payload?.result?.predicted_value,
          email: s?.payload?.result?.email,
          website: s?.payload?.result?.website,
          phone_number: s?.payload?.result?.phone_number,
          facebook: s?.payload?.result?.facebook,
          instagram: s?.payload?.result?.instagram,
          linkedin: s?.payload?.result?.linkedin,
          twitter: s?.payload?.result?.twitter,
        })
        setmerchant_id(s?.payload?.result?.id)
        setmerchantLikeCount(s?.payload?.result?.like_count)
        dispatch(getTargetMerchantHistoryWithId( s?.payload?.result?.id ))
      })
    }
  }

  function createWithRequest(request: any) {
    let temprequest = 
      {
        id: request?.id,
        predicted_value: request?.pvc,
        email: request?.email,
        website: request?.website,
        phone_number: request?.phone_number,
        facebook: request?.facebook,
        instagram: request?.instagram,
        linkedin: request?.linkedin,
        twitter: request?.twitter
      }
    dispatch(putTargetMerchant(temprequest)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getTargetMerchant({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createHistoryWithRequest(request: any) {
    let temp_req =
    {
      target_merchant_id: merchant_id,
      communication_date: request.communication_date,
      message_content: request.message_content,
      target_merchant_communication_channel_type: request.target_merchant_communication_channel_type.value,
    }

    dispatch(postTargetMerchantHistory(temp_req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(
          getTargetMerchantHistoryWithId(merchant_id)
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function callRequestLink() {
    dispatch(getRequestLinkWithId(merchant_id)).then((response) => {
      if (response?.payload?.is_success) {
        window.focus()
        navigator.clipboard.writeText(response?.payload?.result?.onboarding_link)
        showToast.success('Link Copied')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Detail':
        return (
          <>
            <CustomModal title={'Merchant Detail'}
              likeCount={merchantLikeCount}
              maxWidth={1100}
              requestLink={()=> callRequestLink()}
            >
              <>
                <form onSubmit={e => {
                  e.stopPropagation();
                  formik.handleSubmit(e);
                }}>
                  <Tabs
                    defaultActiveKey="details"
                    id="tab"
                    className="mb-3"
                  >
                    <Tab eventKey="details" title="Detail">
                      <div className='row'>
                        <div>
                          <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Merchant Name</label>
                            <CustomTextbox
                              {...formik.getFieldProps('merchant_name')}
                              onChange={() => null}
                              placeHolder={"Type a Name"}
                              name='merchant_name'
                              value={formik.values.merchant_name}
                              formik={formik}
                              formikName={"merchant_name"}
                              disabled={true}
                            ></CustomTextbox>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <div className='row'>
                              <div className='col'>
                                <div className='row'>
                                  <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Lat</label>
                                    <CustomTextbox
                                      {...formik.getFieldProps('lat')}
                                      onChange={() => null}
                                      placeHolder={"Type a Lat"}
                                      name='lat'
                                      value={formik.values.lat}
                                      formik={formik}
                                      formikName={"lat"}
                                      disabled={true}
                                    ></CustomTextbox>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Lng</label>
                                    <CustomTextbox
                                      {...formik.getFieldProps('lng')}
                                      onChange={() => null}
                                      placeHolder={"Type a Lng"}
                                      name='lng'
                                      value={formik.values.lng}
                                      formik={formik}
                                      formikName={"lng"}
                                      disabled={true}
                                    ></CustomTextbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>PVC</label>
                                <CustomTextbox
                                  {...formik.getFieldProps('pvc')}
                                  onChange={() => null}
                                  placeHolder={"Type a PVC"}
                                  name='pvc'
                                  value={formik.values.pvc}
                                  formik={formik}
                                  formikName={"pvc"}
                                ></CustomTextbox>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Social Media</label>
                                <div className='row'>
                                  <div className='col-1 mx-3 mb-2'>
                                    <FontAwesomeIcon icon={faFacebook} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div className='col'>
                                    <CustomTextbox
                                      {...formik.getFieldProps('facebook')}
                                      onChange={() => null}
                                      placeHolder={"Type a Facebook Url"}
                                      name='facebook'
                                      value={formik.values.facebook}
                                      formik={formik}
                                      formikName={"facebook"}
                                    ></CustomTextbox>
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-1 mx-3 mb-2'>
                                    <FontAwesomeIcon icon={faInstagram} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div className='col'>
                                    <CustomTextbox
                                      {...formik.getFieldProps('instagram')}
                                      onChange={() => null}
                                      placeHolder={"Type a Instagram Url"}
                                      name='instagram'
                                      value={formik.values.instagram}
                                      formik={formik}
                                      formikName={"instagram"}
                                    ></CustomTextbox>
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-1 mx-3 mb-2'>
                                    <FontAwesomeIcon icon={faLinkedin} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div className='col'>
                                    <CustomTextbox
                                      {...formik.getFieldProps('linkedin')}
                                      onChange={() => null}
                                      placeHolder={"Type a Linkedin Url"}
                                      name='linkedin'
                                      value={formik.values.linkedin}
                                      formik={formik}
                                      formikName={"linkedin"}
                                    ></CustomTextbox>
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-1 mx-3 mb-2'>
                                    <FontAwesomeIcon icon={faTwitter} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div className='col'>
                                    <CustomTextbox
                                      {...formik.getFieldProps('twitter')}
                                      onChange={() => null}
                                      placeHolder={"Type a Twitter Url"}
                                      name='twitter'
                                      value={formik.values.twitter}
                                      formik={formik}
                                      formikName={"twitter"}
                                    ></CustomTextbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col'>
                            <div className='row'>
                              <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Email</label>
                                <CustomTextbox
                                  {...formik.getFieldProps('email')}
                                  onChange={() => null}
                                  placeHolder={"Type a Email"}
                                  name='email'
                                  value={formik.values.email}
                                  formik={formik}
                                  formikName={"email"}
                                ></CustomTextbox>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Website</label>
                                <CustomTextbox
                                  {...formik.getFieldProps('website')}
                                  onChange={() => null}
                                  placeHolder={"Type a Website"}
                                  name='website'
                                  value={formik.values.website}
                                  formik={formik}
                                  formikName={"website"}
                                ></CustomTextbox>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Phone</label>
                                <CustomPhoneTextbox
                                  {...formik.getFieldProps('phone_number')}
                                  onChange={() => null}
                                  name='phone_number'
                                  value={formik.values.phone_number}
                                  formik={formik}
                                  formikName={'phone_number'}
                                ></CustomPhoneTextbox>
                              </div>
                            </div>
                            <div className='row'>
                                <CustomMap
                                  locations={[{
                                    address: formik.values.merchant_name,
                                    lat: formik.values.lat,
                                    lng: formik.values.lng,
                                  },]}
                                  mapCenter={{ lat: formik.values.lat, lng: formik.values.lng }}
                                ></CustomMap>
                              </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="communication" title="Communication">
                      <form onSubmit={e => {
                        e.stopPropagation();
                        formikHistory.handleSubmit(e);
                      }}>
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button className="accordion-button text-black collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                New Item
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className='row'>
                                  <div className='col'>
                                    <label className='required fw-bold fs-6 mb-2'>Date</label>
                                    <CustomDate
                                      selected={formikHistory.values.communication_date !== "" ? new Date(formikHistory.values.communication_date) : null}
                                      {...formikHistory.getFieldProps('communication_date')}
                                      value={null}
                                      placeHolder={"Select Date"}
                                      formik={formikHistory}
                                      formikName={"communication_date"}
                                      showTimeInput
                                      onChange={undefined}
                                    />
                                  </div>
                                  <div className='col'>
                                    <label className='required fw-bold fs-6 mb-2'>Type</label>
                                    <CustomSelect
                                      onChange={() => null}
                                      data={communicationChannelTypes}
                                      isMulti={false}
                                      selectedValue={formikHistory?.values?.target_merchant_communication_channel_type}
                                      formik={formikHistory}
                                      formikName={"target_merchant_communication_channel_type"}
                                    ></CustomSelect>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Content</label>
                                    <CustomRichTextbox
                                      {...formik.getFieldProps('message_content')}
                                      onChange={() => null}
                                      placeHolder={"Type a Conetent"}
                                      name='message_content'
                                      value={formikHistory.values.message_content}
                                      formik={formikHistory}
                                      formikName={"message_content"}
                                    ></CustomRichTextbox>
                                  </div>
                                </div>
                                <div className='row d-flex justify-content-end '>
                                  <div className='col-2'>
                                    <button
                                      type='button'
                                      onClick={() => formikHistory.handleSubmit()}
                                      className='btn btn-primary'
                                    >
                                      <span className='indicator-label'>Add New</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </form>
                      <hr></hr>
                      <CustomDatatable
                        columns={columnsHistory}
                        data={targetMerchantHistoryListSlice != null ? targetMerchantHistoryListSlice : []}
                        isBusy={dataTableisBusy}
                      ></CustomDatatable>
                    </Tab>
                  </Tabs>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      onClick={() => formik.handleSubmit()}
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Save</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </>
            </CustomModal >
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => { }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={targetMerchantList?.items}
          title={'Target Merchant List'}
          pagination
          paginationServer
          paginationTotalRows={targetMerchantList?.total_count}
          isBusy={dataTableisBusy}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          isExport
          onExport={() => {
            dispatch(
              exportTargetMerchant({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            )
          }}
          showFilter={true}
          filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const TargetMerchantWrapper: FC<Props> = ({ targetMerchantList }) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <TargetMerchantList targetMerchantList={targetMerchantList} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export { TargetMerchantWrapper }
