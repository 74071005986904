import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { LocationPin } from "./location-pin";
import styles from "../../../_metronic/assets/sass/components/custom-map.module.scss";

const K_SIZE = 5;

interface LocProps {
    lat: number;
    lng: number;
    address: string;
    clickable?: boolean;
}

interface Props {
    locations?: LocProps[];
    mapCenter?: { lat: number; lng: number };
    clickable?: boolean;
}

const CustomMap: React.FC<Props> = ({ mapCenter, locations, clickable }) => {
    const [LocationPins, setLocationPins] = useState<LocProps[]>([]);

    useEffect(() => {
        if (locations) {
            setLocationPins(locations);
        }
    }, [locations]);

    return (
        <div className={styles.maps} key={1}>
            <GoogleMapReact 
                style={{border:"2px solid white", borderRadius:"25px", width:"100%", height:"20rem"}}
                bootstrapURLKeys={{ key: 'AIzaSyAgRSefRZ8TLweqL0LA1D-k4GK2h6NeVBo'}}
                defaultCenter={{ lat: 38.9095, lng: -77.0469 }}
                defaultZoom={7}
                draggable
                center={
                    mapCenter?.lat != null
                        ? { lat: mapCenter.lat, lng: mapCenter.lng }
                        : locations?.length && locations.length > 0
                        ? { lat: locations[0].lat, lng: locations[0].lng }
                        : { lat: 38.9095, lng: -77.0469 }
                }                            
                resetBoundsOnResize
                debounced
                hoverDistance={K_SIZE / 2}
                options={{
                    fullscreenControl: false,
                    zoomControl: false,
                }}
            >
                {LocationPins.map((location) => (
                    <LocationPin
                        key={`${location.lat}-${location.lng}`}
                        lat={location.lat}
                        lng={location.lng}
                        text={location.address}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};

export { CustomMap };
