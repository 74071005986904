
import { callApi, HttpMethods } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";

const getTargetMerchantService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/get",
        method: HttpMethods.POST,
        data: req,
    };
    const result = await callApi(_options);
    return result;
}

const exportTargetMerchantService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/export",
        method: HttpMethods.POST,
        data: req,
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const getTargetMerchantWithIdService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/detail/" + req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const postTargetMerchantHistoryService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/history",
        method: HttpMethods.POST,
        data: req,
    };
    const result = await callApi(_options);
    return result;
};

const getTargetMerchantHistoryWithIdService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/history/" + req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const putTargetMerchantService = async (req: any) => {
    let _options = {
        controller: "/target-merchant",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const getRequestLinkWithIdService = async (req: any) => {
    let _options = {
        controller: "/target-merchant/get-onboarding-link/" + req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const TargetMerchantService = {
    getTargetMerchantService,
    exportTargetMerchantService,
    getTargetMerchantWithIdService,
    postTargetMerchantHistoryService,
    getTargetMerchantHistoryWithIdService,
    putTargetMerchantService,
    getRequestLinkWithIdService
};

export default TargetMerchantService;
