/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { customLoginSlice } from '../../../slices/account'
import { AppDispatch } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { UserModel } from '../core/_models'
import { showToast } from '../../../components/custom-toasts/custom-toasts'
import { putProductionRequest } from '../../../slices/merchantManagement/productionRequestSlice'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const dispatch: AppDispatch = useDispatch();
  const customUserInfo: UserModel = {
    id: 0,
    username: '',
    password: undefined,
    email: '',
    first_name: '',
    last_name: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        let customUserData = {
          api_token: "",
          refreshToken: "",
        }
        dispatch(customLoginSlice({ username: values.email, password: values.password })).then((user) => {
          if (user?.payload?.access_token) {
            customUserData.api_token = user?.payload?.access_token
            customUserData.refreshToken = user?.payload?.access_token
            saveAuth(customUserData)
            customUserInfo.username = values.email;
            customUserInfo.password = values.password;
            customUserInfo.email = values.email;
            customUserInfo.first_name = values.email
            customUserInfo.last_name = values.email
            setCurrentUser(customUserInfo)
            localStorage.setItem("userName", values.email)
          }
          else {
            console.error(user?.payload?.response?.data?.error_description)
            saveAuth(undefined)
            setStatus('The login details are incorrect')
            setSubmitting(false)
            setLoading(false)
            showToast.error(user?.payload?.response?.data?.error_description)
          }
        });

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    let query = window.location.search.substring(1);
    let clearString = query?.replaceAll("Id=", "")
    if (clearString !== undefined && clearString !== null && clearString !== '') {
      let req = {
        id: clearString,
        status: 1,
        reason: null,
      }
      dispatch(putProductionRequest(req)).then((response) => {
        if (response?.payload?.is_success) {
          showToast.success("Approved Successfully")
        }
        else {
          showToast.error(response?.payload?.response_exception?.exception_message)
        }
      })
    }
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >

      {/* begin::Form group */}
      <div className='fv-row mb-8' style={{ textAlign: "center" }}>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/veracity-white.png')}
          className='app-sidebar-logo-default'
        />
      </div>
      <div className='fv-row mb-8' style={{ textAlign: "center" }}>
        <label className='form-label fs-2 fw-bolder text-white'>Visobl Back Office</label>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-white'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          style={{ color: "white" }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-white fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          style={{ color: "white" }}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
          style={{ backgroundColor: "white", color: "black" }}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
