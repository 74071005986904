/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import { AppDispatch } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { getBrandAsset, postBrandAsset, putBrandAsset } from "../../../slices/assetManagement/system/BrandAssetSlice"
import CustomFileUpload from "../../../components/customFileUpload/CustomImageUpload"

interface Props {
    BrandAssetSlice: any
}

const BrandAsset: FC<Props> = ({ BrandAssetSlice }) => {
    const dispatch: AppDispatch = useDispatch();
    const [veracityLogo, setVeracityLogo] = useState<File | null>(null);
    const [freedomPayLogo, setFreedomPayLogo] = useState<File | null>(null);

    const formik = useFormik({
        initialValues: {
            id: '',
            veracityLogoFormik: '',
            freedomPayLogoFormik: '',
        },
        validationSchema: Yup.object().shape({
            veracityLogoFormik: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Veracity Logo is required'),
            freedomPayLogoFormik: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Freedompay Logo is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (formik.values.id) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        formik.setValues({
            id: BrandAssetSlice?.id,
            veracityLogoFormik: BrandAssetSlice?.veracity_logo,
            freedomPayLogoFormik: BrandAssetSlice?.freedompay_logo,
        })
    }, [BrandAssetSlice])


    function updateWithId(request: any) {
        const formData = new FormData();
        formData.append("id", request?.id);
        formData.append("VeracityLogo", veracityLogo !== null ? veracityLogo : "");
        formData.append("FreedompayLogo", freedomPayLogo !== null ? freedomPayLogo : "");

        dispatch(putBrandAsset(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFreedomPayLogo(null)
                setVeracityLogo(null)
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getBrandAsset())
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function createWithRequest(request: any) {
        const formData = new FormData();
        formData.append("VeracityLogo", veracityLogo !== null ? veracityLogo : "");
        formData.append("FreedompayLogo", freedomPayLogo !== null ? freedomPayLogo : "");

        dispatch(postBrandAsset(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFreedomPayLogo(null)
                setVeracityLogo(null)
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getBrandAsset())
                showToast.success("Item Saved Successfully")
            }
        })
    }

    return (
        <>
            <KTCard>
                <KTCardBody>
                    <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} style={{ height: "65vh" }}>
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >
                            <div className='row mb-7'>
                                <div className="col-3">
                                    <label className='fw-bold fs-6 mb-2'>Visobl Logo</label>
                                    <CustomFileUpload
                                        uploadFile={(file: FileList) => {
                                            setVeracityLogo(file[0])
                                        }}
                                        imageString={formik.values.veracityLogoFormik}
                                        accept="image/*"
                                        formik={formik}
                                        formikName={"veracityLogoFormik"}
                                    ></CustomFileUpload>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <div className="col-3">
                                    <label className='fw-bold fs-6 mb-2'>Freedompay Logo</label>
                                    <CustomFileUpload
                                        uploadFile={(file: FileList) => {
                                            setFreedomPayLogo(file[0])
                                        }}
                                        imageString={formik.values.freedomPayLogoFormik}
                                        accept="image/*"
                                        formik={formik}
                                        formikName={"freedomPayLogoFormik"}
                                    ></CustomFileUpload>
                                </div>
                            </div>
                        </div>
                        {/* begin::Actions */}
                        <div className='text-left'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                            >
                                <span className='indicator-label'>Submit</span>
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                </KTCardBody>
            </KTCard>
        </>
    )
}

const BrandAssetWrapper: FC<Props> = ({ BrandAssetSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <BrandAsset BrandAssetSlice={BrandAssetSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { BrandAssetWrapper }
