/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to=''
        title='Merchant Management'
        icon='/media/icons/duotune/finance/fin006.svg'
      >
        <SidebarMenuItem
          to='/ActiveMerchant/List'
          title='Active'
          icon='/media/icons/duotune/general/gen043.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/PendingMerchant/List'
          title='Pending'
          icon='/media/icons/duotune/general/gen013.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/TargetMerchant/List'
          title='Target'
          icon='/media/icons/duotune/maps/map006.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Notification/List'
          title='Send Notification'
          icon='/media/icons/duotune/general/gen007.svg'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/ReferralManagement/List'
        title='Referral Management'
        icon='/media/icons/duotune/finance/fin001.svg'
      ></SidebarMenuItem>
      {/* <SidebarMenuItem
        to='/MemberManagement/List'
        title='Member Management'
        icon='/media/icons/duotune/communication/com014.svg'
        active={false}
      ></SidebarMenuItem> */}
      <SidebarMenuItemWithSub
        to=''
        title='Credential Management'
        icon='/media/icons/duotune/finance/fin008.svg'
      >
        <SidebarMenuItem
          to='/Credentials/List'
          title='Credentials'
          icon='/media/icons/duotune/general/gen048.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/DataSources/List'
          title='Data Sources'
          icon='/media/icons/duotune/coding/cod007.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/CredentialMerchantLogo/List'
          title='Credential Merchant Logo'
          icon='/media/icons/duotune/general/gen048.svg'
        ></SidebarMenuItem>
        {/* <SidebarMenuItem
          to='/CalculationMethods/List'
          title='Calculation Methods'
          icon='/media/icons/duotune/finance/fin007.svg'
          active={false}
        ></SidebarMenuItem> */}
        <SidebarMenuItem
          to='/AttributeFormat/List'
          title='Attribute Formats'
          icon='/media/icons/duotune/coding/cod003.svg'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='Campaign Management'
        icon='/media/icons/duotune/abstract/abs024.svg'
      >
        <SidebarMenuItem
          to='/Outcomes/List'
          title='Outcomes'
          icon='/media/icons/duotune/maps/map007.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Shoutbacks/Shoutbacks'
          title='Shoutbacks'
          icon='/media/icons/duotune/electronics/elc007.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/iZonePoolingSettings/List'
          title='iZone Polling Settings'
          icon='/media/icons/duotune/coding/cod001.svg'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title={'Asset Management'}
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItemWithSub
          to=''
          title={'System'}
          icon='/media/icons/duotune/coding/cod009.svg'
        >
          {/* <SidebarMenuItem
            to='/BrandAsset/List'
            title='Brand Assets'
            icon='/media/icons/duotune/art/art007.svg'
          ></SidebarMenuItem> This menu is temporarily closed. The mobile side is not ready yet */}
          <SidebarMenuItem
            to='/VisoblContactInformation/List'
            title='Visobl Contact Information'
            icon='/media/icons/duotune/maps/map008.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/iZoneSystemMessages/List'
            title='iZone System Messages'
            icon='/media/icons/duotune/abstract/abs007.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/PrivacyPolicy/List'
            title='Privacy Policy'
            icon='/media/icons/duotune/abstract/abs007.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/TinkWebHook/List'
            title='Tink Webhook List'
            icon='/media/icons/customIcons/Tink_symbol_white.svg'
          ></SidebarMenuItem>
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to=''
          title={'Mobile'}
          icon='/media/icons/duotune/electronics/elc003.svg'
        >
          <SidebarMenuItem
            to='/Videos/Videos'
            title='Intro Videos'
            icon='/media/icons/duotune/social/soc007.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/TermsConditions/Mobile'
            title='Terms & Conditions'
            icon='/media/icons/duotune/files/fil008.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/SliderContent/List'
            title='Slider Management'
            icon='/media/icons/duotune/arrows/arr021.svg'
          ></SidebarMenuItem>
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to=''
          title={'Console'}
          icon='/media/icons/duotune/electronics/elc001.svg'
        >
          <SidebarMenuItem
            to='/TermsConditions/Console'
            title='Terms & Conditions'
            icon='/media/icons/duotune/files/fil008.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/FAQ/List'
            title='FAQ'
            icon='/media/icons/duotune/general/gen046.svg'
          ></SidebarMenuItem>
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to=''
          title={'Employee Portal'}
          icon='/media/icons/duotune/electronics/elc002.svg'
        >
          <SidebarMenuItem
            to='/TermsConditions/EmployeePortal'
            title='Terms & Conditions'
            icon='/media/icons/duotune/files/fil008.svg'
          ></SidebarMenuItem>
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='' title={'SDK'} icon='/media/icons/duotune/abstract/abs027.svg'>
          {/* <SidebarMenuItem
            to='/WelcomeScreen/List'
            title='Welcome Screen'
            icon='/media/icons/duotune/arrows/arr021.svg'
          ></SidebarMenuItem> */}
          <SidebarMenuItem
            to='/TermsConditions/SDKPrivate'
            title='Terms & Conditions'
            icon='/media/icons/duotune/files/fil008.svg'
          ></SidebarMenuItem>
          {/* <SidebarMenuItem
            to='/TermsConditions/SDKOpen'
            title='Terms & Conditions - Open'
            icon='/media/icons/duotune/files/fil008.svg'
            active={false}
          ></SidebarMenuItem> */}
          <SidebarMenuItem
            to='/Tender/List'
            title='Tender'
            icon='/media/icons/duotune/general/gen019.svg'
          ></SidebarMenuItem>
          <SidebarMenuItem
            to='/Content/List'
            title='Content'
            icon='/media/icons/duotune/general/gen019.svg'
          ></SidebarMenuItem>
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
            to='/Helper/List'
            title='User Manual'
            icon='/media/icons/duotune/general/gen005.svg'
          ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='xLP Management'
        icon='/media/icons/customIcons/xLP_White.svg'
      >
        <SidebarMenuItem
          to='/Transaction/List'
          title='Transactions'
          icon='/media/icons/duotune/files/fil001.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Incentive/Main'
          title='Incentives'
          icon='/media/icons/duotune/graphs/gra007.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/xLPPurchaseRate/List'
          title='Bundle Prices'
          icon='/media/icons/duotune/finance/fin010.svg'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to=''
        title='FreedomPay Connections'
        icon='/media/icons/customIcons/freedompay-white.svg'
      >
        <SidebarMenuItem
          to='/FPMerchants/List'
          title='FP Merchants'
          icon='/media/icons/duotune/communication/com014.svg'
          active={false}
        ></SidebarMenuItem> */}
        {/* <SidebarMenuItem
          to='/Endpoint/List'
          title='Endpoints'
          icon='/media/icons/duotune/communication/com001.svg'
        ></SidebarMenuItem> */}
        {/* <SidebarMenuItem
          to='/VasEpConfiguration/List'
          title='VAS/EP Configurations'
          icon='/media/icons/duotune/communication/com005.svg'
          active={false}
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/TplConfiguration/List'
          title='TPL Configurations'
          icon='/media/icons/duotune/finance/fin002.svg'
          active={false}
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Report/List'
          title='Reports'
          icon='/media/icons/duotune/graphs/gra001.svg'
          active={false}
        ></SidebarMenuItem> */}
      {/* </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to=''
        title='Sales Assistant'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
      >
        <SidebarMenuItem
          to='/UseCases/List'
          title='Use Cases'
          icon='/media/icons/duotune/technology/teh008.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/PrepareMeeting/List'
          title='Prepare for Meeting'
          icon='/media/icons/duotune/arrows/arr029.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Products/List'
          title='Products'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='/Contracts/List'
          title='Contracts'
          icon='/media/icons/duotune/general/gen005.svg'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
