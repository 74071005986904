import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { MenuTestPage } from '../pages/MenuTestPage'
import CreateHelper from '../pages/assetsManagement/console/Helper/CreateHelper'
import Helper from '../pages/assetsManagement/console/Helper/Helper'
import Notification from '../pages/assetsManagement/console/Notification/Notification'
import Faq from '../pages/assetsManagement/console/faq/Faq'
import SliderContent from '../pages/assetsManagement/mobile/SliderContent'
import Content from '../pages/assetsManagement/sdk/content/content'
import IntroVideos from '../pages/assetsManagement/sdk/introVideos/IntroVideos'
import Tender from '../pages/assetsManagement/sdk/tender/Tender'
import WelcomeScreen from '../pages/assetsManagement/sdk/welcomeScreen/WelcomeScreen'
import BrandAsset from '../pages/assetsManagement/system/BrandAssets'
import PrivacyPolicy from '../pages/assetsManagement/system/privacyPolicy/privacyPolicy'
import VeracityContactInformation from '../pages/assetsManagement/system/veracityContactInformation/VeracityContactInformation'
import TermsConditions from '../pages/assetsManagement/termsConditions/TermsConditions'
import IZonePoolingSettings from '../pages/campaignManagement/iZonePoolingSettings/IZonePollingSettings'
import IZoneSystemMessages from '../pages/campaignManagement/iZoneSystemMessages/IZoneSystemMessages'
import Outcomes from '../pages/campaignManagement/outcomes/Outcomes'
import AttributeFormat from '../pages/credentialManagement/attributeFormat/AttributeFormat'
import CalculationMethods from '../pages/credentialManagement/calculationMethods/CalculationMethods'
import CredentialMerchantLogo from '../pages/credentialManagement/credentialMerchantLogo/CredentialMerchantLogo'
import CredentialDetail from '../pages/credentialManagement/credentials/CredentialDetail'
import Credentials from '../pages/credentialManagement/credentials/Credentials'
import DataSources from '../pages/credentialManagement/dataSource/DataSources'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import DataSourceIntegration from '../pages/dataSourceIntegration/DataSourceIntegration'
import Endpoint from '../pages/freedompayConnections/endpoints/Endpoints'
import FPMerchants from '../pages/freedompayConnections/fpMerchants/FPMerchants'
import Report from '../pages/freedompayConnections/reports/report'
import TplConfiguration from '../pages/freedompayConnections/tplConfigurations/tplConfiguration'
import VasEpConfiguration from '../pages/freedompayConnections/vasEpConfigurations/vasEpConfiguration'
import IncentiveMain from '../pages/frpManagement/Incentive/IncentiveMain'
import FrpPurchaseRate from '../pages/frpManagement/frpPurchaseRate/FrpPurchaseRate'
import Transaction from '../pages/frpManagement/transaction/transaction'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import MemberManagement from '../pages/memberManagement/MemberManagement'
import ActiveMerchant from '../pages/merchantManagement/active/ActiveMerchant'
import PendingMerchant from '../pages/merchantManagement/pending/PendingMerchant'
import ReferralManagement from '../pages/referralManagement/ReferralManagement'
import Contracts from '../pages/salesAsistantManagement/contracts/Contracts'
import PrepareMeeting from '../pages/salesAsistantManagement/prepare/PrepareMeeting'
import Products from '../pages/salesAsistantManagement/products/Products'
import UseCases from '../pages/salesAsistantManagement/useCases/UseCases'
import UserManual from '../pages/userManual/UserManual'
import PrepareDetail from '../pages/salesAsistantManagement/prepare/PrepareDetail'
import TinkWebHook from '../pages/assetsManagement/system/tinkWebHook/tinkWebHook'
import TargetMerchant from '../pages/merchantManagement/target/TargetMerchant'

const PrivateRoutes = () => {
  const VideoTsCsAssetManagement = lazy(
    () =>
      import('../pages/assetsManagement/mobile/videoTsCsAssetManagement/VideoTsCsAssetManagement')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='ActiveMerchant/*'
          element={
            <SuspensedView>
              <ActiveMerchant />
            </SuspensedView>
          }
        />
        <Route
          path='PendingMerchant/*'
          element={
            <SuspensedView>
              <PendingMerchant />
            </SuspensedView>
          }
        />
        <Route
          path='ReferralManagement/*'
          element={
            <SuspensedView>
              <ReferralManagement />
            </SuspensedView>
          }
        />
        <Route
          path='MemberManagement/*'
          element={
            <SuspensedView>
              <MemberManagement />
            </SuspensedView>
          }
        />
        <Route
          path='Credentials/*'
          element={
            <SuspensedView>
              <Credentials />
            </SuspensedView>
          }
        />
        <Route
          path='DataSources/*'
          element={
            <SuspensedView>
              <DataSources />
            </SuspensedView>
          }
        />
        <Route
          path='CalculationMethods/*'
          element={
            <SuspensedView>
              <CalculationMethods />
            </SuspensedView>
          }
        />
        <Route
          path='AttributeFormat/*'
          element={
            <SuspensedView>
              <AttributeFormat />
            </SuspensedView>
          }
        />
        <Route
          path='Outcomes/*'
          element={
            <SuspensedView>
              <Outcomes />
            </SuspensedView>
          }
        />
        <Route
          path='iZoneSystemMessages/*'
          element={
            <SuspensedView>
              <IZoneSystemMessages />
            </SuspensedView>
          }
        />
        <Route
          path='iZonePoolingSettings/*'
          element={
            <SuspensedView>
              <IZonePoolingSettings />
            </SuspensedView>
          }
        />
        <Route
          path='Videos/*'
          element={
            <SuspensedView>
              <VideoTsCsAssetManagement />
            </SuspensedView>
          }
        />
        <Route
          path='Shoutbacks/*'
          element={
            <SuspensedView>
              <DataSourceIntegration />
            </SuspensedView>
          }
        />
        <Route
          path='CredentialMerchantLogo/*'
          element={
            <SuspensedView>
              <CredentialMerchantLogo />
            </SuspensedView>
          }
        />
        <Route
          path='SliderContent/*'
          element={
            <SuspensedView>
              <SliderContent />
            </SuspensedView>
          }
        />
        <Route
          path='TermsConditions/Mobile'
          element={
            <SuspensedView>
              <TermsConditions type={2} />
            </SuspensedView>
          }
        />
        <Route
          path='TermsConditions/Console'
          element={
            <SuspensedView>
              <TermsConditions type={1} />
            </SuspensedView>
          }
        />
        <Route
          path='TermsConditions/EmployeePortal'
          element={
            <SuspensedView>
              <TermsConditions type={5} />
            </SuspensedView>
          }
        />
        <Route
          path='Incentive/*'
          element={
            <SuspensedView>
              <IncentiveMain />
            </SuspensedView>
          }
        />
        <Route
          path='xLPPurchaseRate/*'
          element={
            <SuspensedView>
              <FrpPurchaseRate />
            </SuspensedView>
          }
        />
        <Route
          path='BrandAsset/*'
          element={
            <SuspensedView>
              <BrandAsset />
            </SuspensedView>
          }
        />
        <Route
          path='VisoblContactInformation/*'
          element={
            <SuspensedView>
              <VeracityContactInformation />
            </SuspensedView>
          }
        />
        <Route
          path='PrivacyPolicy/*'
          element={
            <SuspensedView>
              <PrivacyPolicy />
            </SuspensedView>
          }
        />
        <Route
          path='FAQ/*'
          element={
            <SuspensedView>
              <Faq />
            </SuspensedView>
          }
        />
        <Route
          path='Helper/*'
          element={
            <SuspensedView>
              <Helper />
            </SuspensedView>
          }
        />
        <Route
          path='Notification/*'
          element={
            <SuspensedView>
              <Notification />
            </SuspensedView>
          }
        />
        <Route
          path='Transaction/*'
          element={
            <SuspensedView>
              <Transaction />
            </SuspensedView>
          }
        />
        <Route
          path='Endpoint/*'
          element={
            <SuspensedView>
              <Endpoint />
            </SuspensedView>
          }
        />
        <Route
          path='VasEpConfiguration/*'
          element={
            <SuspensedView>
              <VasEpConfiguration />
            </SuspensedView>
          }
        />
        <Route
          path='TplConfiguration/*'
          element={
            <SuspensedView>
              <TplConfiguration />
            </SuspensedView>
          }
        />
        <Route
          path='Report/*'
          element={
            <SuspensedView>
              <Report />
            </SuspensedView>
          }
        />
        <Route
          path='Credentials/CredentialDetail'
          element={
            <SuspensedView>
              <CredentialDetail />
            </SuspensedView>
          }
        />
        <Route
          path='Helper/CreateHelper'
          element={
            <SuspensedView>
              <CreateHelper />
            </SuspensedView>
          }
        />
        <Route
          path='FPMerchants/*'
          element={
            <SuspensedView>
              <FPMerchants />
            </SuspensedView>
          }
        />
        <Route
          path='SDKVideos/*'
          element={
            <SuspensedView>
              <IntroVideos />
            </SuspensedView>
          }
        />
        <Route
          path='TermsConditions/SDKPrivate'
          element={
            <SuspensedView>
              <TermsConditions type={6} />
            </SuspensedView>
          }
        />
        {/* <Route
          path='TermsConditions/SDKOpen'
          element={
            <SuspensedView>
              <TermsConditions type={7} />
            </SuspensedView>
          }
        /> */}
        <Route
          path='WelcomeScreen/*'
          element={
            <SuspensedView>
              <WelcomeScreen />
            </SuspensedView>
          }
        />
        <Route
          path='Tender/*'
          element={
            <SuspensedView>
              <Tender />
            </SuspensedView>
          }
        />
        <Route
          path='Content/*'
          element={
            <SuspensedView>
              <Content />
            </SuspensedView>
          }
        />
        <Route
          path='UseCases/*'
          element={
            <SuspensedView>
              <UseCases />
            </SuspensedView>
          }
        />
        <Route
          path='PrepareMeeting/*'
          element={
            <SuspensedView>
              <PrepareMeeting />
            </SuspensedView>
          }
        />
        <Route
          path='PrepareMeeting/PrepareDetail'
          element={
            <SuspensedView>
              <PrepareDetail />
            </SuspensedView>
          }
        />
        <Route
          path='Contracts/*'
          element={
            <SuspensedView>
              <Contracts />
            </SuspensedView>
          }
        />
        <Route
          path='Products/*'
          element={
            <SuspensedView>
              <Products />
            </SuspensedView>
          }
        />
        <Route
          path='UserManual/*'
          element={
            <SuspensedView>
              <UserManual />
            </SuspensedView>
          }
        />
        <Route
          path='TinkWebHook/*'
          element={
            <SuspensedView>
              <TinkWebHook />
            </SuspensedView>
          }
        />
        <Route
          path='TargetMerchant/*'
          element={
            <SuspensedView>
              <TargetMerchant />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

