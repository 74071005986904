/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../_metronic/layout/core'
import CustomChart from '../../components/custom-chart/custom-chart'
import { DashboardCard } from '../../components/DashboardCard'
import { getActiveCounts, getFrpData, getHealthyStatus, getHybridHealthyStatus } from '../../slices/dashboard/dashboardSlice'
import { AppDispatch } from '../../store/store'
import Styles from "../../../_metronic/assets/sass/components/dashboard.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons'

const options = { //data on the x-axis
  chart: { id: 'sdk_chart' },
  xaxis: {
    categories: ["Total Merchant Count", "Private Path Merchant Count", "Open Path Merchant Count"]
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  title: {
    text: 'SDK Usage',
  },
  plotOptions: {
    bar: {
      distributed: true,
      horizontal: true,
      dataLabels: {
        position: 'bottom'
      },
    }
  },
};
const series = [ //data on the y-axis
  {
    name: "Value",
    data: [30, 10, 20]
  }
];

const optionsFrpUsed = { //data on the x-axis
  chart: { id: 'frp_used_chart' },
  xaxis: {
    categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  },
  colors: ["#00dafc", "#effc00", "#fc1100", "#fc00eb"],
  title: {
    text: 'xLP Usage',
  },
}

const optionsFrpBalance = { //data on the x-axis
  chart: {
    id: 'frp_balance_chart',
  },
  labels: ["Consumer xLP Balance", "Merchant xLP Balance"],
  colors: ["#fc1100", "#00dafc"],
  title: {
    text: 'xLP Balance',
  },
}

const DashboardPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [activeCred, setActiveCred] = useState("");
  const [activeMerchant, setActiveMerchant] = useState("");
  const [activeQuery, setActiveQuery] = useState("");
  const [activeWallet, setActiveWallet] = useState("");
  const [activeBadges, setActiveBadges] = useState("");
  const [activeConsumer, setActiveConsumer] = useState("");
  const [activeHbridMerchant, setActiveHbridMerchant] = useState("");
  const [isClosed, setIsClosed] = useState(true);

  const [seriesFrpUsed, setSeriesFrpUsed] = useState<any>([]);
  const [seriesFrpBalance, setSeriesFrpBalance] = useState<any>([]);
  const [backOfficeHealtyStatus, setbackOfficeHealtyStatus] = useState("Unhealthy")
  const [authApiHealtyStatus, setauthApiHealtyStatus] = useState("")
  const [azureblobHealtyStatus, setazureblobHealtyStatus] = useState("")
  const [sqlserverHealtyStatus, setsqlserverHealtyStatus] = useState("")
  const [vmtHealtyStatus, setVmtHealtyStatus] = useState("")
  const [vcdsHealtyStatus, setVcdsHealtyStatus] = useState("")
  const [hybridAuthHealtyStatus, setHybridAuthHealtyStatus] = useState("")
  const [sdkHealtyStatus, setSdkHealtyStatus] = useState("")



  useEffect(() => {
    dispatch(getActiveCounts()).then(x => {
      let res = x.payload?.result;
      setActiveCred(res !== undefined ? res?.active_credentials : "0")
      setActiveMerchant(res !== undefined ? res?.active_merchants : "0")
      setActiveQuery(res !== undefined ? res?.active_queries : "0")
      setActiveWallet(res !== undefined ? res?.active_wallets : "0")
      setActiveBadges(res !== undefined ? res?.active_badges : "0")
      setActiveConsumer(res !== undefined ? res?.active_consumers : "0")
      setActiveHbridMerchant(res !== undefined ? res?.active_hybrid_merchants : "0")
    })

    dispatch(getFrpData()).then(x => {
      let res = x.payload?.result;
      let mb = res?.merchant_bonus?.map((x: any) => x.count);
      let cb = res?.consumer_bonus?.map((x: any) => x.count);
      let pr = res?.frp_purchase?.map((x: any) => x.count);
      let us = res?.frp_used?.map((x: any) => x.count);
      let arr = [{
        name: "Merchant Bonus",
        data: mb
      },
      {
        name: "Consumer Bonus",
        data: cb
      },
      {
        name: 'Purchase',
        data: pr
      },
      {
        name: 'Used',
        data: us
      }]
      setSeriesFrpUsed(arr)
      setSeriesFrpBalance(res !== undefined ? [res?.consumer_balance, res?.merchant_balance] : [0, 0])
    })

    dispatch(getHealthyStatus()).then(x => {
      let res = x.payload;
      if (res !== undefined) {
        setbackOfficeHealtyStatus("Healthy")
        setauthApiHealtyStatus(res?.entries?.authapi?.status)
        setazureblobHealtyStatus(res?.entries?.azureblob?.status)
        setsqlserverHealtyStatus(res?.entries?.sqlserver?.status)
        setVcdsHealtyStatus(res?.entries?.vcdsapi?.status)
      }
    })
    dispatch(getHybridHealthyStatus()).then(x => {
      let res = x.payload;
      if (res !== undefined && res?.result !== undefined) {
        setVmtHealtyStatus(res?.result?.vmt_status ? "Healthy" : "Unhealthy")
        setSdkHealtyStatus(res?.result?.sdk_status ? "Healthy" : "Unhealthy")
        setHybridAuthHealtyStatus(res?.result?.hybrid_auth_status ? "Healthy" : "Unhealthy")
      }
    })
  }, [])

  return <>
    <div className={`${Styles.container}`}>
      <div>
        {
          isClosed ?
            <div className="row">
              <div className="col-1">
                <FontAwesomeIcon
                  icon={faChevronsRight} size={"2x"} onClick={() => { setIsClosed(!isClosed) }}
                ></FontAwesomeIcon>
              </div>
              <div className="col">
                <div className={Styles.title}>{`Wallet Server Status`}</div>
              </div>
            </div>
            :
            <div className='row'>
              <div className="col">
                <div className="row">
                  <div className={`${Styles.title} col`}>{`Hybrid Server Status`}</div>
                  <div className="col text-end">
                    <FontAwesomeIcon
                      icon={faChevronsLeft} size={"2x"} onClick={() => { setIsClosed(!isClosed) }}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className={Styles.title}>{`Wallet Server Status`}</div>
              </div>
            </div>
        }
        <div className="row mt-3">
          {/* first col */}
          <div className="col" style={{ display: isClosed ? "none" : "", borderRight: "1px solid" }}>
            {/* left server status */}
            <div>
              <div className="row mt">
                <div className="col" >
                  <DashboardCard
                    description={`VCDS Api`}
                    backgroudColor={vcdsHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={vcdsHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={vcdsHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={vcdsHealtyStatus}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description={`VMT Api`}
                    backgroudColor={vmtHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={vmtHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={vmtHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={vmtHealtyStatus}
                  ></DashboardCard>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col" >
                  <DashboardCard
                    description={`SDK Api`}
                    backgroudColor={sdkHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={sdkHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={sdkHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={sdkHealtyStatus}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description={`Hybrid Auth Api`}
                    backgroudColor={hybridAuthHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={hybridAuthHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={hybridAuthHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={hybridAuthHealtyStatus}
                  ></DashboardCard>
                </div>
              </div>
            </div>
            <hr />
            {/* left active count */}
            <div>
              <div className='row'>
                <div className="col" >
                  <DashboardCard
                    description='Active Badges'
                    backgroudColor='#009ef7'
                    titleColor='white'
                    fontColor='white'
                    count={activeBadges}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description='Active Consumers'
                    backgroudColor='#181c32'
                    titleColor='white'
                    fontColor='white'
                    count={activeConsumer}
                  ></DashboardCard>
                </div>
              </div>
              <div className='row mt-3'>
                <div className="col" >
                  <DashboardCard
                    description='Active Merchants'
                    backgroudColor='#FF7C2F'
                    titleColor='white'
                    fontColor='white'
                    count={activeHbridMerchant}
                  ></DashboardCard>
                </div>
                <div className="col" >
                </div>
              </div>
            </div>
            <hr />
            {/* left sdk usage */}
            <div className='row'>
              <div className="col">
                <CustomChart options={options} series={series} type="bar" />
              </div>
            </div>
          </div>
          {/* second col */}
          <div className="col">
            {/* right server status */}
            <div>
              <div className="row">
                <div className="col" >
                  <DashboardCard
                    description={`BackOffice Api`}
                    backgroudColor={backOfficeHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={backOfficeHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={backOfficeHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={backOfficeHealtyStatus}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description={`Auth Api`}
                    backgroudColor={authApiHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={authApiHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={authApiHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={authApiHealtyStatus}
                  ></DashboardCard>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col" >
                  <DashboardCard
                    description={`Azure Blob`}
                    backgroudColor={azureblobHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={azureblobHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={azureblobHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={azureblobHealtyStatus}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description={`SQL Server`}
                    backgroudColor={sqlserverHealtyStatus === "Healthy" ? '#50cd89' : "#f1416c"}
                    titleColor={sqlserverHealtyStatus === "Healthy" ? 'white' : "black"}
                    fontColor={sqlserverHealtyStatus === "Healthy" ? 'white' : "black"}
                    count={sqlserverHealtyStatus}
                  ></DashboardCard>
                </div>
              </div>
            </div>
            <hr />
            {/* right active count */}
            <div>
              <div className='row'>
                <div className="col" >
                  <DashboardCard
                    description='Active Credentials'
                    backgroudColor='#009ef7'
                    titleColor='white'
                    fontColor='white'
                    count={activeCred}
                  ></DashboardCard>
                </div>
                <div className="col" >
                  <DashboardCard
                    description='Active Wallets'
                    backgroudColor='#181c32'
                    titleColor='white'
                    fontColor='white'
                    count={activeWallet}
                  ></DashboardCard>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col'>
                  <DashboardCard
                    description='Active Queries'
                    backgroudColor='#FF7C2F'
                    titleColor='white'
                    fontColor='white'
                    count={activeQuery}
                  ></DashboardCard>
                </div>
                <div className='col'>
                  <DashboardCard
                    description='Active Merchants'
                    backgroudColor='#7239ea'
                    titleColor='white'
                    fontColor='white'
                    count={activeMerchant}
                  ></DashboardCard>
                </div>
              </div>
            </div>
            <hr />
            {/* right frp tables */}
            {
              isClosed ?
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <CustomChart options={optionsFrpUsed} series={seriesFrpUsed} type="line" />
                  </div>
                  <div className="col-xl-6 col-sm-12 ">
                    <CustomChart options={optionsFrpBalance} series={seriesFrpBalance} type="pie" />
                  </div>
                </div>
                :
                <div>
                  <div className="row">
                    <div className="col-10">
                      <CustomChart options={optionsFrpUsed} series={seriesFrpUsed} type="line" />
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-10">
                      <CustomChart options={optionsFrpBalance} series={seriesFrpBalance} type="pie" />
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  </>
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
