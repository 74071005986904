/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import FPMerchantsSlice, { deleteFPMerchantsWithId, getFPMerchants, getFPMerchantsWithId, putFPMerchants } from "../../../slices/fpConnections/fpMerchants/fpMerchantsSlice"
import CustomSelect from "../../../components/customModal/CustomSelect"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import { Tab, Tabs } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons"

interface Props {
    FPMerchantsListSlice: any[]
}

const merchantList = [
    {
        value: 0,
        label: "Shake Shack"
    },
]

const FPMerchantsList: FC<Props> = ({ FPMerchantsListSlice }) => {
    const [totalRowCountVal, settotalRowCountVal] = useState(0)
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const [resetDataTable, setresetDataTable] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)

    const formikNew = useFormik({
        initialValues: {
            id: '',
            merchant: undefined,
            fpId: '',
            sdkStatus: null,
        },
        validationSchema: Yup.object().shape({
            merchant: Yup.object().required('Merchant Type is required'),
            fpId: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Freedompay Id is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    //createWithRequest(values)
                    TempUpdateListWithData(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })
    const formikConfigure = useFormik({
        initialValues: {
            id: '',
            public: false,
            private: false,
            customError: ''
        },
        validationSchema: Yup.object().shape({
        }),
        onSubmit: (values, actions) => {
            try {
                if (values.private || values.public) {
                    setprocessName("Email")
                }
                else{
                    actions.setErrors({ customError: 'At least one must be selected.' });
                }
            } catch (ex) {
                console.error(ex)
            }
        },
    })

    function TempUpdateListWithData(values: any) {
        let req = {
            merchant: values?.merchant?.value,
            fpId: values?.fpId,
            sdkStatus: values?.sdkStatus,
        }
        dispatch(FPMerchantsSlice.actions.tempAddList({ item: req }))
    }

    function tempUdid() {
        formikNew.setFieldValue('fpId', "6dff9db6-a63a-11ed-afa1-0242ac120002")
    }

    function Save() {
        let req = {
            merchant: FPMerchantsListSlice[0]?.merchant,
            fpId: FPMerchantsListSlice[0]?.fpId,
            sdkStatus: true,
        }
        dispatch(FPMerchantsSlice.actions.tempAddList({ item: req }))
        showToast.success("Item Saved Succesfully")
        dispatch(componentsSlice.actions.closeModal({}))
    }



    useEffect(() => {
        setdataTableisBusy(false)
        settotalRowCountVal(FPMerchantsListSlice?.length)
        setresetDataTable(!resetDataTable)
    }, [FPMerchantsListSlice])

    const columns = [
        {
            name: 'Merchants Name',
            selector: (row: any) => <div>{merchantList.find(x => x.value === row?.merchant)?.label}</div>,
            width: "auto"
        },
        {
            name: 'Freedompay Id',
            selector: (row: any) => <div>{row?.fpId}</div>,
            width: "auto"
        },
        {
            name: 'SDK Status',
            selector: (row: any) => row?.sdkStatus !== null ? row?.sdkStatus ?
                <span className='badge badge-light-success fs-5 fw-semibold'>{row?.sdkStatus.toString()}</span> :
                <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.sdkStatus.toString()}</span> : null
            ,
            width: "auto",
        },
        {
            name: 'Action',
            selector: (row: any) =>
                <>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                        {
                            <>
                                <div className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                                    data-bs-toggle='tooltip'
                                    title={"Configuration"}
                                    onClick={() => {
                                        setprocessName("Config")
                                        setSelectedRowId(row.fpId)
                                        dispatch(componentsSlice.actions.openModal({}))
                                        FPMerchantsWithId(row.fpId)
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-3' />
                                </div>
                                {
                                    row?.sdkStatus !== null ?
                                        <div className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                                            data-bs-toggle='tooltip'
                                            title={"Resend SDK Documents"}
                                            onClick={() => {
                                                setprocessName("Email")
                                                setSelectedRowId(row.fpId)
                                                dispatch(componentsSlice.actions.openModal({}))
                                            }}
                                        >
                                            <KTSVG path='/media/icons/duotune/abstract/abs036.svg' className='svg-icon-3' />
                                        </div> : null
                                }
                            </>
                        }
                    </div>
                </>
            ,
            width: "100px",
        },
    ];

    useEffect(() => {
        //setdataTableisBusy(true) Temp closed until api ready
        //dispatch(getStorage());  Temp closed until api ready
    }, [activePage, pageSize])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formikNew.resetForm()
            formikConfigure.resetForm()
        }
    }, [modalIsOpen])

    const onChangePage = (page: any) => {
        setActivePage(page);
    };
    const onChangeRowsPerPage = (size: any) => {
        setPageSize(size);
    };
    // For DataTable Event End

    function FPMerchantsWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getFPMerchantsWithId(rowId)).then((s: any) => {
                formikNew.setValues({
                    id: s?.payload?.result,
                    merchant: s?.payload?.result,
                    fpId: s?.payload?.result,
                    sdkStatus: s?.payload?.result,
                })
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: request.id,
            name: request.name,
            is_active: request.isActive,
        }
        dispatch(putFPMerchants(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getFPMerchants())
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteFPMerchantsWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getFPMerchants())
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getFPMerchants())
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    //Temp Closed.
    // function createWithRequest(request: any) {
    //     let req = {
    //         name: request.name,
    //         is_active: request.isActive,
    //     }
    //     dispatch(postFPMerchants(req)).then((response) => {
    //         if (response?.payload?.is_success) {
    //             dispatch(componentsSlice.actions.closeModal({}))
    //             dispatch(getFPMerchants())
    //             showToast.success("Item Saved Successfully")
    //         }
    //         else {
    //             showToast.error(response?.payload?.response_exception?.exception_message)
    //         }
    //     })
    // }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Freedompay Merchant Update" : "New Freedompay Merchant"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formikNew.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Merchants</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={merchantList}
                                            isMulti={false}
                                            selectedValue={formikNew?.values?.merchant}
                                            formik={formikNew}
                                            formikName={"merchant"}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Freedompay Id</label>
                                        <div className="row">
                                            <div className="col-10">
                                                <CustomTextbox
                                                    {...formikNew.getFieldProps('fpId')}
                                                    onChange={() => null}
                                                    placeHolder={"Freedompay Id"}
                                                    name='fpId'
                                                    value={formikNew.values.fpId}
                                                    formik={formikNew}
                                                    formikName={"fpId"}
                                                ></CustomTextbox>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    type='reset'
                                                    className='btn btn-primary'
                                                    onClick={() => tempUdid()}
                                                >
                                                    <span className='indicator-label'>Map</span>
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Config":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Freedompay Merchant Configure" : "New Freedompay Merchant"}>
                        <>

                            <form id='kt_modal_add_user_form' className='form' onSubmit={formikConfigure.handleSubmit}>
                                <Tabs
                                    defaultActiveKey="sdk"
                                    id="tab"
                                    className="mb-3"
                                >
                                    <Tab eventKey="sdk" title="SDK Configure">
                                        <div
                                            className='d-flex flex-column scroll-y me-n7 pe-7'
                                            id='kt_modal_add_user_scroll'
                                            data-kt-scroll='true'
                                            data-kt-scroll-activate='{default: false, lg: true}'
                                            data-kt-scroll-max-height='auto'
                                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                            data-kt-scroll-offset='300px'
                                        >
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-check form-check-solid'>
                                                        <div className='fv-row mb-7 mt-3'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                value=''
                                                                id='kt_private'
                                                                defaultChecked={formikConfigure.values.private}
                                                                checked={formikConfigure.values.private}
                                                                onChange={(event) =>
                                                                    formikConfigure?.setValues({
                                                                        ...formikConfigure?.values,
                                                                        private: event.target.checked
                                                                    })
                                                                }
                                                                disabled={formikConfigure.values.public}
                                                            />
                                                            <label
                                                                className='form-check-label ps-2'
                                                                htmlFor='kt_private'
                                                            >
                                                                Private
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className='fv-row mb-7 mt-3'>
                                                        <div className='form-check form-check-solid'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                value=''
                                                                id='kt_public'
                                                                defaultChecked={formikConfigure.values.public}
                                                                checked={formikConfigure.values.public}
                                                                onChange={(event) =>
                                                                    formikConfigure?.setValues({
                                                                        ...formikConfigure?.values,
                                                                        public: event.target.checked
                                                                    })
                                                                }
                                                                disabled={formikConfigure.values.private}
                                                            />
                                                            <label
                                                                className='form-check-label ps-2'
                                                                htmlFor='kt_public'
                                                            >
                                                                Public
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formikConfigure.errors.customError && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formikConfigure.errors.customError}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        {/* begin::Actions */}
                                        <div className='text-center pt-15'>
                                            <button
                                                type='reset'
                                                onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                                className='btn btn-light me-3'
                                                data-kt-users-modal-action='cancel'
                                            >
                                                Discard
                                            </button>

                                            <button
                                                type='submit'
                                                className='btn btn-primary'
                                                data-kt-users-modal-action='submit'
                                            >
                                                <span className='indicator-label'>Save</span>
                                            </button>
                                        </div>
                                    </Tab>
                                </Tabs>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Email":
                return <>
                    <CustomModal title={"Email"}>
                        <>
                            <div
                                className='d-flex flex-column scroll-y me-n7 pe-7'
                                id='kt_modal_add_user_scroll'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                data-kt-scroll-offset='300px'
                            >
                                <div className='fw-bold text-gray-800 fs-6 mb-2'>{`To: admin@shakeshack.com`}</div>
                                <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                    <div className='flex-grow-1 me-2'>
                                        <span className='fw-bold text-gray-800 fs-6'>
                                            Hello
                                        </span>
                                        <span className='text-muted fw-semibold d-block mt-2'>You can download the <a href="#">SDK</a> and the attached document to integrate Visobl into your mobile application.</span>
                                        <span className='text-muted fw-semibold d-block mt-2'>With the Visobl SDK, you add Visobl general features. With this integration, you will enrich your application with the features of Visobl.</span>
                                        <span className='text-muted fw-semibold d-block mt-4'>For more information, please email to admin@visobl.com.</span>
                                    </div>
                                </div>
                                <div className='d-flex mt-4'>
                                    <div className='d-flex flex-column'>
                                        <div className='fs-5 text-dark fw-bolder'>
                                            {`Attachment`}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: '2.5rem', cursor: "pointer", marginTop: "0.8rem" }}>
                                    <FontAwesomeIcon icon={faFileAlt} size="3x" onClick={() => null}></FontAwesomeIcon>
                                </div>

                            </div>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='submit'
                                    onClick={() => Save()}
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Save Only
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => Save()}
                                >
                                    <span className='indicator-label'>Save & Send Mail</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={FPMerchantsListSlice}
                    title={"Freedompay Merchant List"}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowCountVal}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    isBusy={dataTableisBusy}
                    
                    showTitle
isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const FPMerchantsWrapper: FC<Props> = ({ FPMerchantsListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <FPMerchantsList FPMerchantsListSlice={FPMerchantsListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { FPMerchantsWrapper }
