import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KTSVG } from '../../../_metronic/helpers'
import componentsSlice from '../../slices/components/componentsSlice';
import { AppDispatch, useAppDispatch } from '../../store/store';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { faLink } from '@fortawesome/pro-light-svg-icons';

interface CustomProps {
  title?: string
  likeCount?: string
  requestFunction?: any
}

const CustomModalHeader: React.FC<CustomProps> = ({
  title,
  likeCount,
  requestFunction
}) => {

  const dispatch: AppDispatch = useAppDispatch();
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      {
        likeCount != undefined ?
          <div className='row'>
            <div className='col-auto'>
              <div>
                <button type="button" className="btn bg-black">
                  <>
                    <div className='row'>
                      <div className='col-auto'>
                        <FontAwesomeIcon icon={faLink} color='white' size='xl'></FontAwesomeIcon>
                      </div>
                      <div className='col-auto'>
                        <span className="badge text-white fs-4" onClick={requestFunction}>{"Onboarding Link"}</span>
                      </div>
                    </div>
                  </>
                </button>
              </div>
            </div>
            <div className='col-auto'>
              <div>
                <button type="button" className="btn bg-black">
                  <>
                    <div className='row'>
                      <div className='col'>
                        <FontAwesomeIcon icon={faThumbsUp} color='white' size='xl'></FontAwesomeIcon>
                      </div>
                      <div className='col'>
                        <span className="badge text-white fs-4">{likeCount}</span>
                      </div>
                    </div>
                  </>
                </button>
              </div>
            </div>
            <div className='col'>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
          </div>
          : <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-users-modal-action='close'
            onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
            style={{ cursor: 'pointer' }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
      }


      {/* end::Close */}
    </div>
  )
}

export { CustomModalHeader }
