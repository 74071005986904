/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import Styles from "../../../../_metronic/assets/sass/pages/videoList.module.scss"
import credentialsSlice, { deleteCredentialsWithId, getCredentials, saveSchemaJson, publishCDS, exportCredentials } from "../../../slices/credentialManagement/credentials/CredentialsSlice"
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fontAwesomeHelper from "../../../utils/font-awesome-helper"
import { IconDefinition } from "@fortawesome/free-brands-svg-icons"
import { faX } from "@fortawesome/pro-solid-svg-icons"
import * as htmlToImage from 'html-to-image';
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import CustomSelect from "../../../components/customModal/CustomSelect"

interface Props {
  CredentialsListSlice: any
}

const CredentialsList: FC<Props> = ({ CredentialsListSlice }) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components);
  const dispatch: AppDispatch = useDispatch();
  const [processName, setprocessName] = useState("Insert")
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const navigate = useNavigate();
  const location = useLocation();
  let customPageName = location.pathname.substring(0, location.pathname.indexOf("/", 2))
  let customLocalStorage = localStorage.getItem(customPageName)
  const [customQueryParams, setcustomQueryParams] = useState(customLocalStorage !== null ? JSON.parse(customLocalStorage) : [])
  const [page, setPage] = useState(customQueryParams?.page !== undefined ? customQueryParams?.page : 0)
  const [perPage, setPerPage] = useState(customQueryParams?.perPage !== undefined ? customQueryParams?.perPage : 10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([ { "property": "CreateDateUtc", "direction": 1 }])
  const [filterParams, setfilterParams] = useState<any>([])




  useEffect(() => {
    dispatch(componentsSlice.actions.closeModal({}))
    dispatch(credentialsSlice.actions.clearItems({}))
  }, [])

  useEffect(() => {
    setdataTableisBusy(false)
  }, [CredentialsListSlice])

  const DataProviderTypesEnum = [
    {
      value: -1,
      label: "Select..."
    },
    {
      value: 0,
      label: "OpenBanking"
    },
    {
      value: 1,
      label: "Facebook"
    },
    {
      value: 2,
      label: "Instagram"
    },
    {
      value: 3,
      label: "LinkedIn"
    },
    {
      value: 4,
      label: "Device"
    },
    {
      value: 5,
      label: "None"
    },
    {
      value: 6,
      label: "FreedomPay BI"
    },
    {
      value: 7,
      label: "X"
    },
  ]
  const GroupNameEnum = [
    {
      value: -1,
      label: "Select..."
    },
    {
        value: "Income",
        label: "Income"
    },
    {
        value: "Expenditure",
        label: "Expenditure"
    },
    {
        value: "Social / Behaviour",
        label: "Social / Behaviour"
    },
    {
        value: "Natural Identity",
        label: "Natural Identity"
    },
]

  const downloadImage = async (icon: any) => {
    const canvas = document.getElementById(icon);
    const dataUrl = await htmlToImage.toPng(canvas!);

    // download image
    const link = document.createElement('a');
    link.download = `${icon}.png`;
    link.href = dataUrl;
    link.click();
  }

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Icon',
        selector: (row: any) => <>
          <div className={Styles.imageRow} style={{ cursor: "pointer" }}>
            <div id={row.icon} >
              <FontAwesomeIcon
                icon={row?.icon !== undefined ? fontAwesomeHelper(row?.icon) as IconDefinition : faX} size={"2x"} style={{ marginTop: "0.5rem" }}
                onClick={() => downloadImage(row?.icon)}
              ></FontAwesomeIcon>
            </div>
          </div>
        </>,
        width: "70px",
      },
      {
        name: 'Name',
        selector: (row: any) => <div>{row?.name}</div>,
        width: "185px",
      },
      {
        name: 'Cred Price',
        selector: (row: any) => <div>{Number(row?.cred_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " xLP"}</div>,
        width: "110px",
        right: true,
        sortable: true,
        label: "CredPrice",
      },
      {
        name: 'Data Provider Type',
        selector: (row: any) => <div>{DataProviderTypesEnum.find(x => x.value === row?.data_provider_type)?.label}</div>,
        width: "190px",
      },
      {
        name: 'Version',
        selector: (row: any) => <div>{row?.version}</div>,
        width: "90px",
        sortable: true,
        label: "Version",
      },
      {
        name: 'About',
        selector: (row: any) => <div>{row?.about}</div>,
      },
      {
        name: 'Group Name',
        selector: (row: any) => <div>{row?.group_name}</div>,
      },
      {
        name: 'Status',
        selector: (row: any) => row?.is_active ?
          <span className='badge badge-light-success fs-5 fw-semibold'>{row?.is_active.toString()}</span> :
          <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.is_active.toString()}</span>
        ,
      },
      {
        name: 'Action',
        selector: (row: any) =>
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>

              {
                <>
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      navigate("/Credentials/CredentialDetail")
                      dispatch(credentialsSlice.actions.subCatIsValid({ valid: true }))
                      dispatch(credentialsSlice.actions.changeSelectedCredentialId({ credentialId: row?.id }))
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName("Delete")
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }

            </div>
          </>
        ,
        width: "100px",
      },
    ], []
  )

  const customFilter = [
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>Name</label>
        <CustomTextbox
          onChange={
            (e) => isNotEmpty(e) ?
              setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name"), { "name": "Name", "comparator": "Contains", "value": e }])
              : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name")])
          }
          placeHolder={"Type a Name"}
          name='name'
          value={filterQuery.find((x: any) => x.name === "Name") !== undefined ? filterQuery.find((x: any) => x.name === "Name")?.value : ""}
          customKeypressEvent={() => setfilterParams(filterQuery)}
        ></CustomTextbox>
      </>
    },
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>Data Provider Type</label>
        <CustomSelect
          onChange={
            (e: any) =>
              e.value !== -1 ?
                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "DataProviderType"), { "name": "DataProviderType", "comparator": "Equal", "value": e.value }])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "DataProviderType")])
          }
          data={DataProviderTypesEnum}
          isMulti={false}
          selectedValue={DataProviderTypesEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "DataProviderType")?.value) !== undefined ? DataProviderTypesEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "DataProviderType")?.value) : -1}
        ></CustomSelect>
      </>
    },
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>About</label>
        <CustomTextbox
          onChange={
            (e) => isNotEmpty(e) ?
              setfilterQuery([...filterQuery.filter((x: any) => x.name !== "About"), { "name": "About", "comparator": "Contains", "value": e }])
              : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "About")])
          }
          placeHolder={"Type an About"}
          name='name'
          value={filterQuery.find((x: any) => x.name === "About") !== undefined ? filterQuery.find((x: any) => x.name === "About")?.value : ""}
          customKeypressEvent={() => setfilterParams(filterQuery)}
        ></CustomTextbox>
      </>
    },
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>Group Name</label>
        <CustomSelect
          onChange={
            (e: any) =>
              e.value !== -1 ?
                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "GroupName"), { "name": "GroupName", "comparator": "Contains", "value": e.value }])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "GroupName")])
          }
          data={GroupNameEnum}
          isMulti={false}
          selectedValue={GroupNameEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "GroupName")?.value) !== undefined ? GroupNameEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "GroupName")?.value) : -1}
        ></CustomSelect>
      </>
    },
    {
      component: <>
        <div className='form-check form-check-solid' style={{ marginTop: "2.9rem" }}>
          <input
            className='form-check-input'
            type='checkbox'
            id='is_active'
            checked={filterQuery.find((x: any) => x.name === "IsActive") !== undefined ? filterQuery.find((x: any) => x.name === "IsActive")?.value : false}
            onChange={(e) => setfilterQuery([...filterQuery.filter((x: any) => x.name !== "IsActive"), { "name": "IsActive", "comparator": "Equal", "value": e.target.checked }])}
          />
          <label
            className='form-check-label ps-2'
            htmlFor='is_active'
          >
            Is Active
          </label>
        </div>
      </>
    },
  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true);
    setPage(page - 1)
    setdataTableisBusy(false);
  };
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true);
    setPerPage(newPerPage);
    setdataTableisBusy(false);
  };
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{ "property": column?.label, "direction": sortDirection === "asc" ? 0 : 1 }])
  };
  useEffect(() => {
    setdataTableisBusy(true);
    if (customQueryParams?.page !== undefined || customQueryParams?.perPage !== undefined || customQueryParams?.filterQuery !== undefined || customQueryParams?.sortData !== undefined) {
      setfilterQuery(customQueryParams?.filterQuery !== undefined ? customQueryParams?.filterQuery : [])
      setPage(customQueryParams?.page)
      setPerPage(customQueryParams?.perPage)
      setfilterParams(customQueryParams?.filterQuery !== undefined ? customQueryParams?.filterQuery : [])
      setsortData(customQueryParams?.sortData)
      setcustomQueryParams([])
    }
    else {
      if (page !== 0 || perPage !== 10 || filterParams.length !== 0 || sortData.length !== 0) {
        let tempCustomQueryParams = {
          filterQuery: filterParams,
          page: page,
          perPage: perPage,
          sortData: sortData,
        }
        localStorage.setItem(customPageName, JSON.stringify(tempCustomQueryParams))
      }
      else {
        localStorage.removeItem(customPageName)
      }
      dispatch(getCredentials({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }

  }, [page, perPage, sortData, filterParams])


  //#endregion DataTable Methods End

  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deleteCredentialsWithId(selectedRowId)).then((s: any) => {
        if (s?.payload?.is_success) {
          dispatch(getCredentials({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
          showToast.success("Item successfully deleted")
          dispatch(componentsSlice.actions.closeModal({}))
        }
        else {
          dispatch(getCredentials({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
          showToast.error(s?.payload?.response_exception?.exception_message)
          dispatch(componentsSlice.actions.closeModal({}))
        }
      })
    }
  }

  function publishToCDS() {
    dispatch(publishCDS()).then((s: any) => {
      if (s?.payload?.is_success) {
        showToast.success("Credentials were published.")
        dispatch(getCredentials({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
      }
      else {
        showToast.error(s?.payload?.response_exception?.exception_message)
      }
    })

  }
  function createJsonLDFile() {
    dispatch(saveSchemaJson()).then((s: any) => {
      if (s?.payload?.is_success) {
        showToast.success("Create Json LD Files")
        dispatch(getCredentials({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
      }
      else {
        showToast.error(s?.payload?.response_exception?.exception_message)
      }
    })

  }

  function findProcessScreen(processName: string) {
    if (processName === "Delete") {
      return <>
        <CustomModal title="Are You Sure?">
          <>
            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => {
                  dispatch(componentsSlice.actions.closeModal({}))
                }

                }
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
              >
                Discard
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                onClick={() => {
                  deleteWithId()
                }
                }
              >
                <span className='indicator-label'>Delete</span>
              </button>
            </div>
            {/* end::Actions */}
          </>
        </CustomModal>
      </>
    } else {
      return null;
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={CredentialsListSlice?.items}
          title={"Credential List"}
          paginationTotalRows={CredentialsListSlice?.total_count}
          pagination
          paginationServer
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={"New Item"}
          isExport
          onExport={() => {

            dispatch(exportCredentials({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          showFilter={true}
          filterOptions={customFilter}
          newItemClickEvent={() => {
            navigate("/Credentials/CredentialDetail")
            dispatch(credentialsSlice.actions.clearItems({}))
          }}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
            localStorage.removeItem(customPageName)
          }}
          customFilterIsOpen={filterParams?.length > 0}
          customPaginationDefaultPage={customQueryParams?.page !== undefined ? (customQueryParams?.page + 1) : (page + 1)}
          customPaginationPerPage={customQueryParams?.perPage}
          disablePaginationToggle={true}
        ></CustomDatatable>
        <div className="row">
          <div className="col">
            <div className="col-6 mx-6 mb-5">
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => publishToCDS()}
              >
                <span className='indicator-label'>Publish Credentials to CDS</span>
              </button>
            </div>
          </div>
          <div className="col">
            <div className="me-5 mb-5 ml-5 d-flex justify-content-end">
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => createJsonLDFile()}
              >
                <span className='indicator-label'>Create JSON LD</span>
              </button>
            </div>
          </div>
        </div>
      </KTCard>
      {modalIsOpen !== undefined ?
        findProcessScreen(processName)

        : null
      }
    </>
  )
}

const CredentialsWrapper: FC<Props> = ({ CredentialsListSlice }) => <QueryRequestProvider>
  <ListViewProvider>
    <CredentialsList CredentialsListSlice={CredentialsListSlice} />
  </ListViewProvider>
</QueryRequestProvider>

export { CredentialsWrapper }
